<template>
  <div>
    <v-row>
      <v-col cols="6">
        {{ $t('lead.clientUUID') }}
      </v-col>
      <v-col cols="6">
        {{ client != null ? client.data.uuid : '' }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('lead.clientName') }}
      </v-col>
      <v-col cols="6">
        {{ client != null ? client.data.name : '' }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('lead.clientEmail') }}
      </v-col>
      <v-col cols="6">
        {{ client != null ? client.data.email : '' }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        {{ $t('lead.clientPhone') }}
      </v-col>
      <v-col cols="6">
        {{ client != null ? client.data.phone_number : '' }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ['client'],
};
</script>
